import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "./Loading";
import { getCollaborator } from "../redux/appSlices/collaborator/collaboratorSlice";
import { useDispatch } from "react-redux";
import { useGetCollaboratorQuery } from "../redux/appSlices/collaborator/collaboratorApiSlice";
// import usePwaNavigation from "../hooks/usePwaNavigation";

const OCP_REDIRECTION = {
  "PLxJuGPM1CMllgaPcA":"https://ecard.ocpnutricrops.com/bY2zkk9f5cB06rsTX6",
  "4r63sjRcvYWJD3JbB9":"https://ecard.ocpnutricrops.com/VKTrIPJhsKgkCVZj9s",
  "rZ8ttlOnYLvdiwYkNO":"https://ecard.ocpnutricrops.com/lJo9lt2BQctkWeEhiM",
  "rQekA1O9WgIx0y2U2Z":"https://ecard.ocpnutricrops.com/xDp8wiu16KMELFq3qZ",
  "uoArBZVkYt4Vqr3qbM":"https://ecard.ocpnutricrops.com/ASSwvl9951BrvY2Cf4",
  "5t24xUGmNFJzUdJHNm":"https://ecard.ocpnutricrops.com/RgfqW5dnBKCH1y2n49",
  "XMJYG3GtL3lBJJQJYj":"https://ecard.ocpnutricrops.com/MYVoBYLhQwLWtrydY1",
  "vqK016P78VW3k9usO1":"https://ecard.ocpnutricrops.com/jvGvySKn0WJEpxPkL0",
  "grgFTJOzvyrgTbe9bZ":"https://ecard.ocpnutricrops.com/aDV8Up2lrySAlpN7md",
  "lzxZfU7LZXR9jFS4tm":"https://ecard.ocpnutricrops.com/rBJnIvYAXW6ER2o7qw",
  "k0GLGkaQFcF4tQvW6O":"https://ecard.ocpnutricrops.com/cI4EbKt0e7ZbqhM8kf",
  "xkZ83hsrKy8TVsmMSE":"https://ecard.ocpnutricrops.com/jAF65izUikuDukBH1P",
  "K77KVuRC6yX6iTItPf":"https://ecard.ocpnutricrops.com/FVIC0QUWHp2IzJ52qA",
  "Ehpaf7yBXb2bi1U8A3":"https://ecard.ocpnutricrops.com/peXcvtgTQMNYnTYyFL",
  "8Ekkdhu2CODT8owJVF":"https://ecard.ocpnutricrops.com/taiTHsFD6H0DHkJMnX",
  "nT5KLrGzZyCcRK1HT8":"https://ecard.ocpnutricrops.com/OOiL5zA98uvQuZuZeS"
}

function MainLoading() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // usePwaNavigation();
  const [onboardingStep, setOnboardingStep] = useState(null);

  const onboarding_step = null;

  const { data, isLoading } = useGetCollaboratorQuery(id);

  useEffect(() => {
    if (data) {
      // if (data.data.collaborator.backoffice_id._id === "6662f77e71f25fdcf00dae65"){
      //   window.location.href = "https://www.instagram.com/iqos.mar";
      // }
      dispatch(getCollaborator(data.data.collaborator));
    }
    if (onboarding_step) {
      setOnboardingStep(onboarding_step);
    } else if (!onboardingStep && !isLoading) {
      setOnboardingStep(data.data.collaborator.onboarding_step);
    }
  }, [data]);

  //some profiles need to reffers to others
  const [customId, setCustomId] = useState({
    U1tsS5lENooFs2eF6p: "IW41PCB3HRXSIHcyny",
    "8C9XEb0J1PpDF5sPxh": "4MFSgGV5zaFqu9vXow",
  });

  const collaborator_redirections = (id) => {
    if (Object.keys(customId).includes(id)) {
      return customId[id];
    } else {
      return id;
    }
  };

 

  useEffect(() => {
    if (!id) {
      return navigate(`/login`, { replace: true });
    }
    // if (data?.data?.collaborator?.backoffice_id?._id === "6662f77e71f25fdcf00dae65") {
    //   window.location.href = "https://www.instagram.com/iqos.mar";
    // }
    if (onboardingStep === 10) {
      navigate(`/profile/${collaborator_redirections(id)}`, { replace: true });
    } else if (onboardingStep !== null) {
      navigate(`/onboarding/${id}`, { replace: true });
    } else {
    }

    // if (onboardingStep === 10) {
    //   navigate(`/profile/${collaborator_redirections(id)}`, { replace: true });
    // } else if (onboardingStep !== null) {
    //   navigate(`/onboarding/${id}`, { replace: true });
    // } else {
    // }
  }, [onboardingStep]);

  
  if(Object.keys(OCP_REDIRECTION).includes(id)){
    window.location.href = OCP_REDIRECTION[id]
  }

  if (!data && !isLoading) {
    window.location.href = "https://www.getpopcard.com/";
  }

  if (data && !data.data.collaborator.active) {
    window.location.href = "https://www.getpopcard.com/";
  }

  return <Loading />;
}

export default MainLoading;

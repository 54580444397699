import { apiSlice } from "../../api/apiSlice";

export const onboardingApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        registerCollaborator: builder.mutation({
            query: ({authData}) => ({
                url: '/collaborators/register',
                method: 'POST',
                body: {...authData}
            }),
            invalidatesTags: ['collaborator'],
        }),
        // Resend the confirmation Mail
        resendConfirmationMail: builder.mutation({
            query: (collab_id) => ({
                url: `/collaborators/resend-email/${collab_id}`,
                method: 'POST',
            }),
        }),
        patchCollaborator: builder.mutation({
            query: ({id, content}) => ({
                url: `/collaborators/${id}`,
                method: 'PATCH',
                body: { ...content }
            }),
            invalidatesTags: ['collaborator'],
        }),
        postConfirmEmail: builder.mutation({
            query: (id) => ({
                url: `/collaborators/confirm-email/${id}`,
                method: 'POST',
            }),
            invalidatesTags: ['collaborator'],
        }),
    })
})

export const {
    useRegisterCollaboratorMutation,
    useResendConfirmationMailMutation,
    usePatchCollaboratorMutation,
    usePostConfirmEmailMutation
} = onboardingApiSlice
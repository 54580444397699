import React, { useEffect, useState } from 'react'
import { usePostConfirmEmailMutation } from '../redux/appSlices/onboarding/onboardingApiSlice';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../componants/Loading'
import PageNotFound from './PageNotFound';
import { toast } from 'react-toastify';

const VerifyEmail = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [postConfirmEmail, { isLoading, isError }] = usePostConfirmEmailMutation();
  const [hasError, setHasError] = useState(false)



  useEffect(() => {
    postConfirmEmail(id)
      .unwrap()
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        if (error.data.message === "Collaborator already registered") {
          toast.error("Email déjà vérifié. Vous êtes déjà inscrit.");
          navigate("/login");
        } else {
          setHasError(error.data.message)
        }
      });
  }, []);

  if (isError) return <PageNotFound message={hasError}/>
  return <Loading />
}

export default VerifyEmail